/* @flow */

import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Foldable, OffCanvasFilterMenu, ActiveFilters } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import { getCurrentPageInfo } from "state/current-page-info";
import { useSendMessage } from "crustate/react";
import Button from "components/Button";
import CurrentPageInfo from "components/CurrentPageInfo";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import FilterIcon from "icons/filter.svg";
import { useOpenFiltermenu } from "helpers/use-open-filtermenu";
import useAffiliateListFilter from "components/AffiliateListView/use-affiliate-list-filter";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import SearchInput from "components/AffiliateListView/SearchInput";
import DropdownSort from "components/DropdownSort";
import ErrorView from "components/ErrorView";
import { Section, Title, Item } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";
import Carousel from "components/Carousel";
import AffiliateCard from "components/AffiliateCard";
import TopLevelCard from "components/AffiliateListView/TopLevelCard";

import styles from "./styles.scss";

const NUM_DUMMYCARDS = 32;

const AffiliateListView = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { content: { earnview, heroIntroView } } = useContext(StoreInfoContext);
  const [foldableOpen, setFoldableOpen] = useState(false);
  const openFiltermenu = useOpenFiltermenu();
  const { width: browserWidth } = useBrowserDimensions();
  const isDesktop = browserWidth > 800;

  const {
    filterState,
    items,
    itemsSelected,
    searchValue,
    setSearchValue,
    affiliateCategoriesLoaded,
    affiliateListLoaded,
    affiliateListSelectedLoaded,
    affiliateCategoryError,
    affiliateListError } = useAffiliateListFilter();

  useEffect(() => {
    sendMessage(getCurrentPageInfo("EARNVIEW"));
  }, []);

  return (
    <div className={styles.block}>
      <Helmet
        title={earnview.pageTitle}
      />
      <CurrentPageInfo />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro
          title={earnview.introHeading}
          description={earnview.introDescription && earnview.introDescription}
        />
      }
      <Wrapper>
        <div className={cn({ [styles.split]: Boolean(earnview.introImage) })}>
          {Boolean(
            (earnview.introHeading || earnview.introDescription) ||
            (earnview.foldableHeading || earnview.foldableDescription)
          ) &&
            <div className={styles.intro}>
              {Boolean(heroIntroView.toggle) === false &&
              <>
                {earnview.introHeading &&
                  <Title>
                    {earnview.introHeading}
                  </Title>
                }
                {/* eslint-disable react/no-danger */}
                {earnview.introDescription &&
                  <p
                    dangerouslySetInnerHTML={{ __html: earnview.introDescription }}
                  />
                }
                {/* eslint-enable react/no-danger */}
              </>
              }

              <Foldable open={foldableOpen}>
                <div className={styles.section}>
                  {earnview.foldableHeading &&
                    <strong>
                      {earnview.foldableHeading}
                    </strong>
                  }
                  {/* eslint-disable react/no-danger */}
                  {earnview.foldableDescription &&
                    <p
                      className={styles.foldableDescription}
                      dangerouslySetInnerHTML={{ __html: earnview.foldableDescription }}
                    />
                  }
                  {/* eslint-enable react/no-danger */}
                </div>
              </Foldable>

              {Boolean(earnview.foldableHeading) || Boolean(earnview.foldableDescription) ? (
                <Button
                  className={styles.cta}
                  variant="link"
                  onClick={() => setFoldableOpen(!foldableOpen)}
                >
                  {t(foldableOpen ? "EARN.SHOW_LESS" : "EARN.SHOW_MORE")}
                </Button>
              ) : null}
            </div>
          }
          {earnview.introImage && <img className={styles.introImage} src={earnview.introImage} alt="Intro Image" />}
        </div>

        {Boolean(
          (earnview.cardHeading1 && earnview.cardLinkUrl1) ||
          (earnview.cardHeading2 && earnview.cardLinkUrl2) ||
          (earnview.cardHeading3 && earnview.cardLinkUrl3) ||
          (earnview.cardHeading4 && earnview.cardLinkUrl4)
        ) &&
          <div className={styles.cardsWrapper}>
            {earnview.cardHeading1 && earnview.cardLinkUrl1 &&
              <TopLevelCard
                heading={earnview.cardHeading1}
                image={earnview.cardImage1}
                linkUrl={earnview.cardLinkUrl1 && earnview.cardLinkUrl1}
                description={earnview.cardDescription1 && earnview.cardDescription1}
              />
            }
            {earnview.cardHeading2 && earnview.cardLinkUrl2 &&
              <TopLevelCard
                heading={earnview.cardHeading2}
                image={earnview.cardImage2}
                linkUrl={earnview.cardLinkUrl2 && earnview.cardLinkUrl2}
                description={earnview.cardDescription2 && earnview.cardDescription2}
              />
            }
            {earnview.cardHeading3 && earnview.cardLinkUrl3 &&
              <TopLevelCard
                heading={earnview.cardHeading3}
                image={earnview.cardImage3}
                linkUrl={earnview.cardLinkUrl3 && earnview.cardLinkUrl3}
                description={earnview.cardDescription3 && earnview.cardDescription3}
              />
            }
            {earnview.cardHeading4 && earnview.cardLinkUrl4 &&
              <TopLevelCard
                heading={earnview.cardHeading4}
                image={earnview.cardImage4}
                linkUrl={earnview.cardLinkUrl4 && earnview.cardLinkUrl4}
                description={earnview.cardDescription4 && earnview.cardDescription4}
              />
            }
          </div>
        }

        {Boolean(earnview.affiliateListSelectedToggle) &&
          <Section className={styles.affiliateListSelected}>
            {earnview.affiliateListSelectedHeading &&
              <Title elem="h2">{earnview.affiliateListSelectedHeading}</Title>
            }
            {affiliateListSelectedLoaded ?
              <Carousel
                autoplay
                className={cn("awardit-affiliateListSelected", styles.carousel)}
                items={itemsSelected.map((p, i) => (
                  <Item key={`${p.name}_${i}`} className={cn("awardit-affiliateCard", styles.item)}>
                    <AffiliateCard affiliate={p} />
                  </Item>
                ))}
                slidesToScroll={isDesktop ? 4 : 2}
                slidesToShow={isDesktop ? 4 : 2}
                timer={5000}
              /> :
              <div className={styles.dummyCards}>
                <AffiliateDummyList
                  items={Array.from({
                    length: 4,
                  }, () => null)} />
              </div>
            }
          </Section>
        }

        <Section className={styles.section}>
          {earnview.affiliateListHeading &&
            <Title className={styles.heading}>
              {earnview.affiliateListHeading}
            </Title>
          }
          <div className={styles.activeFiltersRow}>
            {filterState.active.filters.length > 0 &&
              <h2 className={styles.activeFiltersRowHeader}>
                {t("FILTER.ACTIVE_FILTERS.COUNT")}
                <span className={styles.activeFiltersRowCount}>{` (${filterState.active.filters.length})`}</span>
              </h2>
            }
            <ActiveFilters
              hideEmptyFilters
              className={styles.activeFilters}
              filterState={filterState}
            />
          </div>
          {affiliateCategoriesLoaded &&
          <div>
            <div className={styles.filterWrapper}>
              <div className={styles.filterSection}>
                <Button
                  variant="medium"
                  slotLeft={<FilterIcon className={styles.filterIcon} />}
                  className={styles.filterButton}
                  onClick={openFiltermenu.openFiltermenu}
                >
                  {t("FILTER.FILTERBAR.ALL_FILTERS")}
                </Button>
                <div className={styles.inputs}>
                  <SearchInput
                    value={searchValue}
                    placeholderText={t("EARN.SEARCH")}
                    clearButtonAriaLabel={t("EARN.CLEAR_SEARCH")}
                    onChange={setSearchValue} />
                  <DropdownSort
                    className={styles.affiliateSort}
                    value={filterState.sort.value}
                    items={filterState.sort.values}
                    onChange={filterState.sort.setValue} />
                </div>
              </div>
            </div>

            {affiliateListLoaded && items.length > 0 &&
            <AffiliateList items={items} />
            }

            {affiliateListLoaded && items.length === 0 &&
            <div className={styles.emptyList}>
              <p>{earnview.emptyListMessage}</p>
            </div>
            }
          </div>
          }

          {!affiliateListLoaded && !affiliateListError &&
            <div className={styles.dummyCards}>
              <AffiliateDummyList
                items={Array.from({
                  length: NUM_DUMMYCARDS,
                }, () => null)} />
            </div>
          }

          {affiliateListError &&
            <ErrorView />
          }
        </Section>
      </Wrapper>
      {/* <Helmet
        title={getTitle(title, activeFilters)}
      /> */}
      {affiliateCategoriesLoaded && !affiliateCategoryError &&
        <OffCanvasFilterMenu
          isOpen={openFiltermenu.isOpen}
          close={openFiltermenu.closeFiltermenu}
          filterState={filterState}
        />
      }
    </div>
  );
};

export default AffiliateListView;
